import ApiService from "@/services/api.service";

// action types
export const LOAD_USER_COMPANY = "loadUserCompany";
export const UPDATE_USER_COMPANY = "updateUserCompany";
export const LOAD_USER_COMPANY_DETAILS = "loadUserCompanyDetails";
export const UPDATE_USER_COMPANY_DETAILS = "updateUserCompanyDetails";
export const LOAD_USER_BRANCH_OFFICES = "loadUserBranchOffices";
export const UPDATE_USER_BRANCH_OFFICES = "updateUserBranchOffices";

// mutation types
export const SET_USER_COMPANY = "setUserCompany";
export const PURGE_USER_COMPANY = "purgeUserCompany";
export const SET_USER_COMPANY_DETAILS = "setUserCompanyDetails";
export const PURGE_USER_COMPANY_DETAILS = "purgeUserCompanyDetails";
export const SET_USER_BRANCH_OFFICES = "setUserBranchOffices";
export const PURGE_USER_BRANCH_OFFICES = "purgeUserBranchOffices";

const USER_COMPANY_URL = "company/company";
const USER_COMPANY_DETAILS_URL = "company/company-details";
const USER_BRANCH_OFFICES_URL = "company/branch-offices";

const Company = {
  state: {
    userCompany: {},
    userCompanyDetails: {},
    userBranchOffices: {}
  },
  getters: {
    getUserCompany(state) {
      return state.userCompany;
    },
    getUserCompanyDetails(state) {
      return state.userCompanyDetails;
    },
    getUserBranchOffices(state) {
      return state.userBranchOffices;
    }
  },
  actions: {
    [LOAD_USER_COMPANY](context) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(USER_COMPANY_URL)
          .then(response => {
            context.commit(SET_USER_COMPANY, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_USER_COMPANY);
            reject(error);
          });
      });
    },
    [UPDATE_USER_COMPANY](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post(USER_COMPANY_URL, payload)
          .then(response => {
            context.commit(SET_USER_COMPANY, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_USER_COMPANY);
            reject(error);
          });
      });
    },
    [LOAD_USER_COMPANY_DETAILS](context) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(USER_COMPANY_DETAILS_URL)
          .then(response => {
            context.commit(SET_USER_COMPANY_DETAILS, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_USER_COMPANY_DETAILS);
            reject(error);
          });
      });
    },
    [UPDATE_USER_COMPANY_DETAILS](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post(USER_COMPANY_DETAILS_URL, payload)
          .then(response => {
            context.commit(SET_USER_COMPANY_DETAILS, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_USER_COMPANY_DETAILS);
            reject(error);
          });
      });
    },
    [LOAD_USER_BRANCH_OFFICES](context) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(USER_BRANCH_OFFICES_URL)
          .then(response => {
            context.commit(SET_USER_BRANCH_OFFICES, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_USER_BRANCH_OFFICES);
            reject(error);
          });
      });
    },
    [UPDATE_USER_BRANCH_OFFICES](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post(USER_BRANCH_OFFICES_URL, payload)
          .then(response => {
            context.commit(SET_USER_BRANCH_OFFICES, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_USER_BRANCH_OFFICES);
            reject(error);
          });
      });
    }
  },
  mutations: {
    [SET_USER_COMPANY](state, response) {
      state.userCompany = response.data;
    },
    [PURGE_USER_COMPANY](state) {
      state.userCompany = {};
    },
    [SET_USER_COMPANY_DETAILS](state, response) {
      state.userCompanyDetails = response.data;
    },
    [PURGE_USER_COMPANY_DETAILS](state) {
      state.userCompanyDetails = {};
    },
    [SET_USER_BRANCH_OFFICES](state, response) {
      state.userBranchOffices = response.data;
    },
    [PURGE_USER_BRANCH_OFFICES](state) {
      state.userBranchOffices = {};
    }
  }
};

export default Company;
