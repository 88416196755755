import ApiService from "@/services/api.service";

// action types
export const LOAD_LANGUAGES = "loadLanguages";

// mutation types
export const SET_LANGUAGES = "setLanguages";
export const PURGE_LANGUAGES = "purgeLanguages";

const LANGUAGES_URL = "languages";

const Languages = {
  state: {
    languages: [],
    dataLoaded: false
  },
  getters: {
    getLanguages(state) {
      return state.languages;
    }
  },
  actions: {
    [LOAD_LANGUAGES](context) {
      return new Promise((resolve, reject) => {
        // Check if Languages has already been loaded
        if (context.state.dataLoaded) {
          resolve();
        } else {
          // Load languages from API
          // ApiService.setHeader();
          ApiService.get(LANGUAGES_URL)
            .then(response => {
              context.commit(SET_LANGUAGES, response);
              resolve(response);
            })
            .catch(error => {
              context.commit(PURGE_LANGUAGES);
              reject(error);
            });
        }
      });
    }
  },
  mutations: {
    [SET_LANGUAGES](state, response) {
      state.languages = response.data;
      state.dataLoaded = true;
    },
    [PURGE_LANGUAGES](state) {
      state.languages = [];
      state.dataLoaded = false;
    }
  }
};

export default Languages;
