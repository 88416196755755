import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";
import UserService from "@/services/user.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const RESET_AUTH = "resetAuth";
export const RESET_PASSWORD = "resetPassword";
export const RESET_PASSWORD_CONFIRM = "resetPasswordConfirm";
export const REGISTER = "register";
export const VERIFY_REGISTRATION = "verifyRegistration";

// mutation types
const PURGE_AUTH = "purgeAuth";
const SET_AUTH = "setUser";
const SET_ERROR = "setError";

const LOGIN_URL = "company/login";
const LOGOUT_URL = "logout";
const RESET_PASSWORD_URL = "password/reset";
const RESET_PASSWORD_CONFIRM_URL = "password/reset/confirm";
const REGISTRATION_URL = "company/registration";
const VERIFY_REGISTRATION_URL = "registration/verify-email";

const Authentication = {
  state: {
    errors: null,
    user: {},
    isAuthenticated: !!JwtService.getToken(),
    status: {},
    statusText: {}
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    }
  },

  actions: {
    [LOGIN](context, credentials) {
      return new Promise((resolve, reject) => {
        ApiService.post(LOGIN_URL, credentials)
          .then(response => {
            // console.log("Here what post returns", data);
            context.commit(SET_AUTH, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(SET_ERROR, error);
            reject(error);
          });
      });
    },
    [LOGOUT](context) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post(LOGOUT_URL)
          .then(response => {
            context.commit(PURGE_AUTH);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_AUTH);
            context.commit(SET_ERROR, error);
            reject(error);
          });
      });
    },
    [RESET_AUTH](context) {
      context.commit(PURGE_AUTH);
    },
    [RESET_PASSWORD](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(RESET_PASSWORD_URL, payload)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            context.commit(SET_ERROR, error);
            reject(error);
          });
      });
    },
    [RESET_PASSWORD_CONFIRM](context, payload) {
      return new Promise((resolve, reject) => {
        let reset_url =
          RESET_PASSWORD_CONFIRM_URL + "/" + payload.uid + "/" + payload.token;
        ApiService.post(reset_url, payload)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            context.commit(SET_ERROR, error);
            reject(error);
          });
      });
    },
    [REGISTER](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(REGISTRATION_URL, payload)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            context.commit(SET_ERROR, error);
            reject(error);
          });
      });
    },
    [VERIFY_REGISTRATION](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.post(VERIFY_REGISTRATION_URL, payload)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    [VERIFY_AUTH](context) {
      return new Promise((resolve, reject) => {
        if (JwtService.getToken()) {
          resolve(true);
        } else {
          context.commit(PURGE_AUTH);
          reject(false);
        }
      });
    }
  },

  mutations: {
    [SET_ERROR](state, error) {
      state.errors = error;
    },
    [SET_AUTH](state, response) {
      state.isAuthenticated = true;
      state.errors = null;
      JwtService.saveToken(response.data.access_token);
      JwtService.saveRefreshToken(response.data.refresh_token);
      UserService.saveUser(response.data.user);
    },
    [PURGE_AUTH](state) {
      state.isAuthenticated = false;
      state.errors = null;
      JwtService.destroyTokens();
      UserService.deleteUser();
    }
  }
};

export default Authentication;
