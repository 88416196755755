import ApiService from "@/services/api.service";

// action types
export const LOAD_BKP_NUMBERS = "loadBKPNumbers";

// mutation types
export const SET_BKP_NUMBERS = "setBKPNumbers";
export const PURGE_BKP_NUMBERS = "purgeBKPNumbers";

const BKP_NUMBERS_URL = "bkps";

const BKPNumbers = {
  state: {
    bkpNumbers: [],
    dataLoaded: false
  },
  getters: {
    getBKPNumbers(state) {
      return state.bkpNumbers;
    }
  },
  actions: {
    [LOAD_BKP_NUMBERS](context) {
      return new Promise((resolve, reject) => {
        // Check if BKPs has already been loaded
        if (context.state.dataLoaded) {
          resolve();
        } else {
          // Load BKPs from API
          // ApiService.setHeader();
          ApiService.get(BKP_NUMBERS_URL)
            .then(response => {
              context.commit(SET_BKP_NUMBERS, response);
              resolve(response);
            })
            .catch(error => {
              context.commit(PURGE_BKP_NUMBERS);
              reject(error);
            });
        }
      });
    }
  },
  mutations: {
    [SET_BKP_NUMBERS](state, response) {
      state.bkpNumbers = response.data;
      state.dataLoaded = true;
    },
    [PURGE_BKP_NUMBERS](state) {
      state.bkpNumbers = [];
      state.dataLoaded = false;
    }
  }
};

export default BKPNumbers;
