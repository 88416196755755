import ApiService from "@/services/api.service";

// action types
export const LOAD_LOCALITIES = "loadLocalities";

// mutation types
export const SET_LOCALITIES = "setLocalities";
export const PURGE_LOCALITIES = "purgeLocalities";

const LOCALITIES_URL = "localities";

const Localities = {
  state: {
    localities: [],
    dataLoaded: false
  },
  getters: {
    getLocalities(state) {
      return state.localities;
    }
  },
  actions: {
    [LOAD_LOCALITIES](context) {
      return new Promise((resolve, reject) => {
        // Check if Languages has already been loaded
        if (context.state.dataLoaded) {
          resolve();
        } else {
          // Load languages from API
          // ApiService.setHeader();
          ApiService.get(LOCALITIES_URL)
            .then(response => {
              context.commit(SET_LOCALITIES, response);
              resolve(response);
            })
            .catch(error => {
              context.commit(PURGE_LOCALITIES);
              reject(error);
            });
        }
      });
    }
  },
  mutations: {
    [SET_LOCALITIES](state, response) {
      state.localities = response.data;
      state.dataLoaded = true;
    },
    [PURGE_LOCALITIES](state) {
      state.localities = [];
      state.dataLoaded = false;
    }
  }
};

export default Localities;
