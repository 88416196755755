const ID_USER_FIRST_NAME = "id_user_first_name";
const ID_USER_LAST_NAME = "id_user_last_name";
const ID_USER_EMAIL = "id_user_email";
const ID_IS_REGISTRATION_COMPLETED = "id_is_registration_completed";
const UserService = {
  saveUser(user) {
    window.localStorage.setItem(ID_USER_FIRST_NAME, user.first_name);
    window.localStorage.setItem(ID_USER_LAST_NAME, user.last_name);
    window.localStorage.setItem(ID_USER_EMAIL, user.email);
    window.localStorage.setItem(
      ID_IS_REGISTRATION_COMPLETED,
      user.is_registration_completed
    );
  },
  getFirstName() {
    return window.localStorage.getItem(ID_USER_FIRST_NAME);
  },
  getLastName() {
    return window.localStorage.getItem(ID_USER_LAST_NAME);
  },
  getEmail() {
    return window.localStorage.getItem(ID_USER_EMAIL);
  },
  isRegistrationCompleted() {
    return window.localStorage.getItem(ID_IS_REGISTRATION_COMPLETED) === "true";
  },
  deleteUser() {
    window.localStorage.removeItem(ID_USER_FIRST_NAME);
    window.localStorage.removeItem(ID_USER_LAST_NAME);
    window.localStorage.removeItem(ID_USER_EMAIL);
    window.localStorage.removeItem(ID_IS_REGISTRATION_COMPLETED);
  }
};

export default UserService;
