import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          name: "dashboard",
          path: "/dashboard",
          component: () => import("@/view/pages/admin/Dashboard.vue")
        },
        {
          name: "profile",
          path: "/profile",
          component: () => import("@/view/pages/admin/Profile")
        },
        {
          name: "invoices",
          path: "/invoices",
          component: () => import("@/view/pages/admin/Invoices")
        },
        {
          name: "subscription-order-invoice",
          path: "/subscription-order-invoice/:orderId",
          component: () => import("@/view/pages/admin/SubscriptionOrderInvoice")
        },
        {
          name: "subscription-options-invoice",
          path: "/subscription-order-invoice/:orderId/:optionsId?",
          component: () =>
            import("@/view/pages/admin/SubscriptionOptionsInvoice")
        },
        {
          name: "orders",
          path: "/orders",
          component: () => import("@/view/pages/admin/Orders")
        },
        {
          name: "subscription-order",
          path: "/subscription-order/:orderId",
          component: () => import("@/view/pages/admin/SubscriptionOrder")
        },
        {
          name: "subscription-options",
          path: "/subscription-options/:orderId/:optionsId?",
          component: () => import("@/view/pages/admin/SubscriptionOptions")
        },
        {
          name: "company",
          path: "/company",
          component: () => import("@/view/pages/Company")
        },
        {
          name: "company-portrait",
          path: "/company-portrait",
          component: () => import("@/view/pages/CompanyPortrait")
        },
        {
          name: "company-references",
          path: "/company-references",
          component: () =>
            import(
              "@/view/pages/ReferenceProjects/CompanyReferenceProjectsList"
            )
        },
        {
          name: "company-reference",
          path: "/company-reference/:id?",
          component: () =>
            import("@/view/pages/ReferenceProjects/EditReferenceProject")
        },
        {
          name: "company-details",
          path: "/company-details",
          component: () => import("@/view/pages/CompanyDetails")
        },
        {
          name: "company-services",
          path: "/company-services",
          component: () => import("@/view/pages/CompanyServices")
        },
        {
          name: "branch-offices",
          path: "/branch-offices",
          component: () => import("@/view/pages/BranchOffices")
        },
        {
          name: "adjust-subscription",
          path: "/adjust-subscription",
          component: () => import("@/view/pages/AdjustSubscription")
        },
        {
          name: "add-subscription-option",
          path: "/add-subscription-option",
          component: () => import("@/view/pages/AddSubscriptionOptions")
        },
        {
          name: "user-invitations",
          path: "/user-invitations",
          component: () => import("@/view/pages/UserInvitations")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/layout/AuthLayout"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register")
        },
        {
          name: "reset-password",
          path: "/reset-password/:uid/:token",
          component: () => import("@/view/pages/auth/ResetPassword")
        },
        {
          name: "verify-email",
          path: "/verify-email/:key",
          component: () => import("@/view/pages/auth/VerifyEmail")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
