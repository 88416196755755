import ApiService from "@/services/api.service";
import UserService from "@/services/user.service";

// action types
export const LOAD_USER_PROFILE = "loadUserProfile";
export const UPDATE_USER_PROFILE = "updateUserProfile";
export const CHANGE_PASSWORD = "changePassword";
export const RESET_CHANGE_PASSWORD = "resetChangePassword";

// mutation types
export const SET_USER_PROFILE = "setUserProfile";
export const PURGE_USER_PROFILE = "purgeUserProfile";
export const SET_ERROR = "setError";
export const PURGE_ERROR = "purgeError";

const USER_PROFILE_URL = "company/profile";
const CHANGE_PASSWORD_URL = "password/change";

const Profile = {
  state: {
    errors: null,
    userProfile: {},
    status: {},
    statusText: {}
  },

  getters: {
    getUserProfile(state) {
      return state.userProfile;
    }
  },

  actions: {
    [LOAD_USER_PROFILE](context) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(USER_PROFILE_URL)
          .then(response => {
            context.commit(SET_USER_PROFILE, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_USER_PROFILE);
            reject(error);
          });
      });
    },
    [UPDATE_USER_PROFILE](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post(USER_PROFILE_URL, payload)
          .then(response => {
            context.commit(SET_USER_PROFILE, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(SET_ERROR, error);
            reject(error);
          });
      });
    },
    [CHANGE_PASSWORD](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post(CHANGE_PASSWORD_URL, payload)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            context.commit(SET_ERROR, error);
            reject(error);
          });
      });
    },
    [RESET_CHANGE_PASSWORD](context) {
      context.commit(PURGE_ERROR);
    }
  },

  mutations: {
    [SET_USER_PROFILE](state, response) {
      state.userProfile = response.data;
      UserService.saveUser(response.data);
    },
    [PURGE_USER_PROFILE](state) {
      state.userProfile = {};
      state.errors = {};
    },
    [SET_ERROR](state, error) {
      state.errors = error;
    },
    [PURGE_ERROR](state) {
      state.errors = null;
    }
  }
};

export default Profile;
