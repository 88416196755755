import ApiService from "@/services/api.service";

const ID_TOKEN_KEY = "id_token";
const ID_REFRESH_KEY = "id_refresh";

const REFRESH_TOKEN_URL = "token/refresh";

const JwtService = {
  getToken() {
    return window.localStorage.getItem(ID_TOKEN_KEY);
  },

  saveToken(token) {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
  },

  getRefreshToken() {
    return window.localStorage.getItem(ID_REFRESH_KEY);
  },

  saveRefreshToken(refresh_token) {
    window.localStorage.setItem(ID_REFRESH_KEY, refresh_token);
  },

  getNewToken() {
    return new Promise((resolve, reject) => {
      const refresh = this.getRefreshToken();
      ApiService.post(REFRESH_TOKEN_URL, { refresh })
        .then(response => {
          this.saveToken(response.data.access);
          // this.saveRefreshToken(response.data.refresh);
          resolve(response.data.access);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  destroyTokens() {
    window.localStorage.removeItem(ID_TOKEN_KEY);
    window.localStorage.removeItem(ID_REFRESH_KEY);
  }
};

export default JwtService;
