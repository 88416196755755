import ApiService from "@/services/api.service";

// action types
export const LOAD_USER_COMPANY_REFERENCE_PROJECTS_LIST =
  "loadUserCompanyReferenceProjectsList";
export const LOAD_USER_COMPANY_REFERENCE_PROJECT =
  "loadUserCompanyReferenceProject";
export const UPDATE_USER_COMPANY_REFERENCE_PROJECT =
  "updateUserCompanyReferenceProject";
export const DELETE_USER_COMPANY_REFERENCE_PROJECT =
  "deleteUserCompanyReferenceProject";

// mutation types
export const SET_USER_COMPANY_REFERENCE_PROJECTS_LIST =
  "setUserCompanyReferenceProjectsList";
export const PURGE_USER_COMPANY_REFERENCE_PROJECTS_LIST =
  "purgeUserCompanyReferenceProjectsList";
export const SET_USER_COMPANY_REFERENCE_PROJECT =
  "setUserCompanyReferenceProject";
export const PURGE_USER_COMPANY_REFERENCE_PROJECT =
  "purgeUserCompanyReferenceProject";

const USER_COMPANY_REFERENCE_PROJECTS_LIST_URL = "company/company-references";
const USER_COMPANY_REFERENCE_PROJECT_URL = "company/company-reference";

const ReferenceProjects = {
  state: {
    companyReferenceProjectsList: {},
    companyReferenceProject: {}
  },
  getters: {
    getUserCompanyReferenceProjectsList(state) {
      return state.companyReferenceProjectsList;
    },
    getUserCompanyReferenceProject(state) {
      return state.companyReferenceProject;
    }
  },
  actions: {
    [LOAD_USER_COMPANY_REFERENCE_PROJECTS_LIST](context) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(USER_COMPANY_REFERENCE_PROJECTS_LIST_URL)
          .then(response => {
            context.commit(SET_USER_COMPANY_REFERENCE_PROJECTS_LIST, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_USER_COMPANY_REFERENCE_PROJECTS_LIST);
            reject(error);
          });
      });
    },
    [LOAD_USER_COMPANY_REFERENCE_PROJECT](context, id) {
      return new Promise((resolve, reject) => {
        let url =
          id != null
            ? USER_COMPANY_REFERENCE_PROJECT_URL + "/" + id
            : USER_COMPANY_REFERENCE_PROJECT_URL;
        ApiService.setHeader();
        ApiService.get(url)
          .then(response => {
            context.commit(SET_USER_COMPANY_REFERENCE_PROJECT, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_USER_COMPANY_REFERENCE_PROJECT);
            reject(error);
          });
      });
    },
    [UPDATE_USER_COMPANY_REFERENCE_PROJECT](context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          payload.id != null
            ? USER_COMPANY_REFERENCE_PROJECT_URL + "/" + payload.id
            : USER_COMPANY_REFERENCE_PROJECT_URL;
        ApiService.setHeader();
        ApiService.post(url, payload)
          .then(response => {
            context.commit(SET_USER_COMPANY_REFERENCE_PROJECT, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_USER_COMPANY_REFERENCE_PROJECT);
            reject(error);
          });
      });
    },
    [DELETE_USER_COMPANY_REFERENCE_PROJECT](context, id) {
      return new Promise((resolve, reject) => {
        let url = USER_COMPANY_REFERENCE_PROJECT_URL + "/" + id;
        ApiService.setHeader();
        ApiService.delete(url)
          .then(response => {
            context.commit(PURGE_USER_COMPANY_REFERENCE_PROJECT);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_USER_COMPANY_REFERENCE_PROJECT);
            reject(error);
          });
      });
    }
  },
  mutations: {
    [SET_USER_COMPANY_REFERENCE_PROJECTS_LIST](state, response) {
      state.companyReferenceProjectsList = response.data;
    },
    [PURGE_USER_COMPANY_REFERENCE_PROJECTS_LIST](state) {
      state.companyReferenceProjectsList = {};
    },
    [SET_USER_COMPANY_REFERENCE_PROJECT](state, response) {
      state.companyReferenceProject = response.data;
    },
    [PURGE_USER_COMPANY_REFERENCE_PROJECT](state) {
      state.companyReferenceProject = {};
    }
  }
};

export default ReferenceProjects;
