import ApiService from "@/services/api.service";

// action types
export const LOAD_USER_COMPANY_PORTRAIT = "loadUserCompanyPortrait";
export const UPDATE_USER_COMPANY_PORTRAIT = "updateUserCompanyPortrait";
export const LOAD_USER_COMPANY_PORTRAIT_IMAGES =
  "loadUserCompanyPortraitImages";
export const UPDATE_USER_COMPANY_PORTRAIT_IMAGES =
  "updateUserCompanyPortraitImages";

// mutation types
export const SET_USER_COMPANY_PORTRAIT = "setUserCompanyPortrait";
export const PURGE_USER_COMPANY_PORTRAIT = "purgeUserCompanyPortrait";
export const SET_USER_COMPANY_PORTRAIT_IMAGES = "setUserCompanyPortraitImages";
export const PURGE_USER_COMPANY_PORTRAIT_IMAGES =
  "purgeUserCompanyPortraitImages";

const USER_COMPANY_PORTRAIT_URL = "company/company-portrait";
const USER_COMPANY_PORTRAIT_IMAGES_URL = "company/company-portrait/images";

const Portrait = {
  state: {
    companyPortrait: {},
    companyPortraitImages: {}
  },
  getters: {
    getUserCompanyPortrait(state) {
      return state.companyPortrait;
    },
    getUserCompanyPortraitImages(state) {
      return state.companyPortraitImages;
    }
  },
  actions: {
    [LOAD_USER_COMPANY_PORTRAIT](context) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(USER_COMPANY_PORTRAIT_URL)
          .then(resonse => {
            context.commit(SET_USER_COMPANY_PORTRAIT, resonse);
            resolve(resonse);
          })
          .catch(error => {
            context.commit(PURGE_USER_COMPANY_PORTRAIT);
            reject(error);
          });
      });
    },
    [UPDATE_USER_COMPANY_PORTRAIT](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post(USER_COMPANY_PORTRAIT_URL, payload)
          .then(response => {
            context.commit(SET_USER_COMPANY_PORTRAIT, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_USER_COMPANY_PORTRAIT);
            reject(error);
          });
      });
    },
    [LOAD_USER_COMPANY_PORTRAIT_IMAGES](context) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(USER_COMPANY_PORTRAIT_IMAGES_URL)
          .then(resonse => {
            context.commit(SET_USER_COMPANY_PORTRAIT_IMAGES, resonse);
            resolve(resonse);
          })
          .catch(error => {
            context.commit(PURGE_USER_COMPANY_PORTRAIT_IMAGES);
            reject(error);
          });
      });
    },
    [UPDATE_USER_COMPANY_PORTRAIT_IMAGES](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post(USER_COMPANY_PORTRAIT_IMAGES_URL, payload)
          .then(response => {
            context.commit(SET_USER_COMPANY_PORTRAIT_IMAGES, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_USER_COMPANY_PORTRAIT_IMAGES);
            reject(error);
          });
      });
    }
  },
  mutations: {
    [SET_USER_COMPANY_PORTRAIT](state, response) {
      state.companyPortrait = response.data;
    },
    [PURGE_USER_COMPANY_PORTRAIT](state) {
      state.companyPortrait = {};
    },
    [SET_USER_COMPANY_PORTRAIT_IMAGES](state, response) {
      state.companyPortraitImages = response.data;
    },
    [PURGE_USER_COMPANY_PORTRAIT_IMAGES](state) {
      state.companyPortraitImages = {};
    }
  }
};

export default Portrait;
