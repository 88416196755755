import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import Profile from "./profile.module";
import UserInvoices from "@/store/userInvoices.module";
import Subscription from "@/store/subscription.module";
import BKPNumbers from "./bkpnumbers.module";
import Languages from "./languages.module";
import Localities from "@/store/localities.module";
import Company from "@/store/company.module";
import Dashboard from "@/store/dashboard.module";
import Portrait from "@/store/portrait.module";
import ReferenceProjects from "@/store/referenceprojects.module";
import UserInvitations from "@/store/userinvitations.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    Profile,
    UserInvoices,
    Subscription,
    bkpNumbers: BKPNumbers,
    languages: Languages,
    localities: Localities,
    company: Company,
    dashboard: Dashboard,
    portrait: Portrait,
    referenceProjects: ReferenceProjects,
    userInvitations: UserInvitations
  }
});
