import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import ApiService from "@/services/api.service";
import { VERIFY_AUTH } from "@/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/plugins/vue-i18n";
import vuetify from "@/plugins/vuetify";
import "@/plugins/portal-vue";
import "@/plugins/bootstrap-vue";
import "@/plugins/perfect-scrollbar";
import "@/plugins/highlight-js";
import "@/plugins/inline-svg";
import "@/plugins/apexcharts";
import "@/plugins/treeselect";
import "@/plugins/metronic";
import "@/plugins/recaptcha";
import "@mdi/font/css/materialdesignicons.css";
// import UserService from "@/services/user.service";

// API service init
ApiService.init();

router.beforeEach((to, from, next) => {
  if (
    to.name === "login" ||
    to.name === "register" ||
    to.name === "reset-password" ||
    to.name === "verify-email" ||
    to.name === "adjust-subscription"
  ) {
    // Always allow login page
    next();
  } else {
    // Ensure we checked auth before each page load.
    Promise.all([store.dispatch(VERIFY_AUTH)])
      .then(() => {
        next();
        // Check if the registration completed
        // if (UserService.isRegistrationCompleted()) {
        //   next();
        // } else {
        //   next({ name: "adjust-subscription" });
        // }
      })
      .catch(() => {
        next({ name: "login" });
      });
  }

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
