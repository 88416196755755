import axios from "axios";
import JwtService from "@/services/jwt.service";
import router from "@/router";

const REFRESH_TOKE_URL = "token/refresh/";
const LOGIN_URL = "login/";

// axios.interceptors.response.use(config => {
//   const token = JwtService.getToken();
//   if (token) {
//     config.config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

axios.interceptors.response.use(
  response => {
    // Return a successful response back to the calling service
    return response;
  },
  error => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // Logout user if token refresh didn't work or user is not authorized
    if (
      error.config.url === REFRESH_TOKE_URL ||
      error.config.url === LOGIN_URL
      // error.response.data.detail ===
      //   "No active account found with the given credentials"
    ) {
      JwtService.destroyTokens();
      router.push({ name: "login" });

      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // Try request again with new token
    return JwtService.getNewToken()
      .then(token => {
        // New request with new token
        const config = error.config;
        config.headers["Authorization"] = `Bearer ${token}`;

        return new Promise((resolve, reject) => {
          axios
            .request(config)
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        });
      })
      .catch(error => {
        Promise.reject(error);
      });
  }
);

export default axios;
