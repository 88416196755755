import ApiService from "@/services/api.service";

// action types
export const LOAD_USER_INVITATIONS = "loadUserInvitations";
export const LOAD_USER_INVITATION_INVITED_USER =
  "loadUserInvitationInvitedUser";

// mutation types
export const SET_USER_INVITATIONS = "setUserInvitations";
export const PURGE_USER_INVITATIONS = "purgeUserInvitations";
export const SET_USER_INVITATION_INVITED_USER = "setUserInvitationInvitedUser";
export const PURGE_USER_INVITATION_INVITED_USER =
  "purgeUserInvitationInvitedUser";

const USER_INVITATIONS_URL = "user-invitations";
const USER_INVITATION_INVITED_USER_URL = "user-invitation/invited-user";

const UserInvitations = {
  state: {
    userInvitations: [],
    userInvitationInvitedUser: null
  },
  getters: {
    getUserInvitations(state) {
      return state.userInvitations;
    },
    getUserInvitationInvitedUser(state) {
      return state.userInvitationInvitedUser;
    }
  },
  actions: {
    [LOAD_USER_INVITATIONS](context) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(USER_INVITATIONS_URL)
          .then(response => {
            context.commit(SET_USER_INVITATIONS, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_USER_INVITATIONS);
            reject(error);
          });
      });
    },
    [LOAD_USER_INVITATION_INVITED_USER](context) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(USER_INVITATION_INVITED_USER_URL)
          .then(response => {
            context.commit(SET_USER_INVITATION_INVITED_USER, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_USER_INVITATION_INVITED_USER);
            reject(error);
          });
      });
    }
  },
  mutations: {
    [SET_USER_INVITATIONS](state, response) {
      state.userInvitations = response.data;
    },
    [PURGE_USER_INVITATIONS](state) {
      state.userInvitations = [];
    },
    [SET_USER_INVITATION_INVITED_USER](state, response) {
      if (response.status === 200) {
        state.userInvitationInvitedUser = response.data;
      }
    },
    [PURGE_USER_INVITATION_INVITED_USER](state) {
      state.userInvitationInvitedUser = null;
    }
  }
};

export default UserInvitations;
