import ApiService from "@/services/api.service";

// action types
export const LOAD_SUBSCRIPTION_PRICING = "loadSubscriptionPricing";
export const LOAD_SUBSCRIPTION_ORDER = "loadSubscription";
export const ADJUST_SUBSCRIPTION = "adjustSubscription";
export const LOAD_SUBSCRIPTION_OPTIONS = "loadSubscriptionOptions";
export const ADD_SUBSCRIPTION_OPTIONS = "addSubscriptionOptions";

// mutation types
export const SET_SUBSCRIPTION_PRICING = "setSubscriptionPricing";
export const PURGE_SUBSCRIPTION_PRICING = "purgeSubscriptionPricing";
export const SET_SUBSCRIPTION_ORDER = "setSubscriptionOrder";
export const PURGE_SUBSCRIPTION_ORDER = "purgeSubscriptionOrder";
export const SET_SUBSCRIPTION_OPTIONS = "setSubscriptionOptions";
export const PURGE_SUBSCRIPTION_OPTIONS = "purgeSubscriptionOptions";

const SUBSCRIPTION_PRICING_URL = "subscription-pricing";
const ADJUST_SUBSCRIPTION_URL = "company/subscription";
const SUBSCRIPTION_OPTIONS_URL = "company/subscription-options";

const Subscription = {
  state: {
    subscriptionPricing: {},
    isSubscriptionPricingLoaded: false,
    subscriptionOrder: {},
    isSubscriptionOrderLoaded: false,
    subscriptionOptions: {},
    isSubscriptionOptionsLoaded: false
  },
  getters: {
    getSubscriptionPricing(state) {
      return state.subscriptionPricing;
    },
    getSubscriptionOrder(state) {
      return state.subscriptionOrder;
    },
    getSubscriptionOptions(state) {
      return state.subscriptionOptions;
    }
  },
  actions: {
    [LOAD_SUBSCRIPTION_PRICING](context) {
      return new Promise((resolve, reject) => {
        // Check if subscription pricing is already loaded
        if (context.state.isSubscriptionPricingLoaded) {
          resolve();
        } else {
          ApiService.setHeader();
          ApiService.get(SUBSCRIPTION_PRICING_URL)
            .then(response => {
              context.commit(SET_SUBSCRIPTION_PRICING, response);
              resolve(response);
            })
            .catch(error => {
              context.commit(PURGE_SUBSCRIPTION_PRICING);
              reject(error);
            });
        }
      });
    },
    [LOAD_SUBSCRIPTION_ORDER](context) {
      return new Promise((resolve, reject) => {
        // Check if subscription order is already loaded
        if (context.state.isSubscriptionOrderLoaded) {
          resolve();
        } else {
          ApiService.setHeader();
          ApiService.get(ADJUST_SUBSCRIPTION_URL)
            .then(response => {
              context.commit(SET_SUBSCRIPTION_ORDER, response);
              resolve(response);
            })
            .catch(error => {
              context.commit(PURGE_SUBSCRIPTION_ORDER);
              reject(error);
            });
        }
      });
    },
    [ADJUST_SUBSCRIPTION](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post(ADJUST_SUBSCRIPTION_URL, payload)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    [LOAD_SUBSCRIPTION_OPTIONS](context) {
      return new Promise((resolve, reject) => {
        // Check if subscription order is already loaded
        if (context.state.isSubscriptionOptionsLoaded) {
          resolve();
        } else {
          ApiService.setHeader();
          ApiService.get(SUBSCRIPTION_OPTIONS_URL)
            .then(response => {
              context.commit(SET_SUBSCRIPTION_OPTIONS, response);
              resolve(response);
            })
            .catch(error => {
              context.commit(PURGE_SUBSCRIPTION_OPTIONS);
              reject(error);
            });
        }
      });
    },
    [ADD_SUBSCRIPTION_OPTIONS](context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post(SUBSCRIPTION_OPTIONS_URL, payload)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  mutations: {
    [SET_SUBSCRIPTION_PRICING](state, response) {
      state.subscriptionPricing = response.data[0];
      state.isSubscriptionPricingLoaded = true;
    },
    [PURGE_SUBSCRIPTION_PRICING](state) {
      state.subscriptionPricing = {};
      state.isSubscriptionPricingLoaded = false;
    },
    [SET_SUBSCRIPTION_ORDER](state, response) {
      state.subscriptionOrder = response.data;
      state.isSubscriptionOrderLoaded = true;
    },
    [PURGE_SUBSCRIPTION_ORDER](state) {
      state.subscriptionOrder = {};
      state.isSubscriptionOrderLoaded = false;
    },
    [SET_SUBSCRIPTION_OPTIONS](state, response) {
      state.subscriptionOptions = response.data;
      state.isSubscriptionOptionsLoaded = true;
    },
    [PURGE_SUBSCRIPTION_OPTIONS](state) {
      state.subscriptionOptions = {};
      state.isSubscriptionOptionsLoaded = false;
    }
  }
};

export default Subscription;
