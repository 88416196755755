import ApiService from "@/services/api.service";

// action types
export const LOAD_USER_INVOICES = "loadUserInvoices";
export const LOAD_USER_INVOICE = "loadUserInvitation";

// mutation types
export const SET_USER_INVOICES = "setUserInvoices";
export const PURGE_USER_INVOICES = "purgeUserInvoices";
export const SET_USER_INVOICE = "setUserInvitation";
export const PURGE_USER_INVOICE = "purgeUserInvitation";

const USER_INVOICES_URL = "user-invoices";
const USER_INVOICE_URL = "user-invoice";

const UserInvoices = {
  state: {
    userInvoices: {},
    userInvoice: null
  },
  getters: {
    getUserInvoices(state) {
      return state.userInvoices;
    },
    getUserInvoice(state) {
      return state.userInvoice;
    }
  },
  actions: {
    [LOAD_USER_INVOICES](context) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(USER_INVOICES_URL)
          .then(response => {
            context.commit(SET_USER_INVOICES, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_USER_INVOICES);
            reject(error);
          });
      });
    },
    [LOAD_USER_INVOICE](context, payload) {
      return new Promise((resolve, reject) => {
        let url = USER_INVOICE_URL + "/" + payload.id;
        ApiService.setHeader();
        ApiService.get(url)
          .then(response => {
            context.commit(SET_USER_INVOICE, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_USER_INVOICE);
            reject(error);
          });
      });
    }
  },
  mutations: {
    [SET_USER_INVOICES](state, response) {
      state.userInvoices = response.data;
    },
    [PURGE_USER_INVOICES](state) {
      state.userInvoices = [];
    },
    [SET_USER_INVOICE](state, response) {
      state.userInvoice = response.data;
    },
    [PURGE_USER_INVOICE](state) {
      state.userInvoice = null;
    }
  }
};

export default UserInvoices;
