import ApiService from "@/services/api.service";

// action types
export const LOAD_LAST_DASHBOARD_SUBSCRIPTION_ORDER =
  "loadLastDashboardSubscriptionOrder";
export const LOAD_DASHBOARD_SUBSCRIPTION_ORDERS_LIST =
  "loadDashboardSubscriptionOrdersList";
export const LOAD_DASHBOARD_SUBSCRIPTION_ORDER =
  "loadDashboardSubscriptionOrder";
export const LOAD_DASHBOARD_SUBSCRIPTION_OPTIONS_ORDER =
  "loadDashboardSubscriptionOptionsOrder";
export const LOAD_DASHBOARD_PROFILE_STATUS = "loadDashboardProfileStatus";

// mutation types
export const SET_LAST_DASHBOARD_SUBSCRIPTION_ORDER =
  "setLastDashboardSubscriptionOrders";
export const PURGE_LAST_DASHBOARD_SUBSCRIPTION_ORDER =
  "purgeLastDashboardSubscriptionOrders";
export const SET_DASHBOARD_SUBSCRIPTION_ORDERS_LIST =
  "setDashboardSubscriptionOrdersList";
export const PURGE_DASHBOARD_SUBSCRIPTION_ORDERS_LIST =
  "purgeDashboardSubscriptionOrdersList";
export const SET_DASHBOARD_SUBSCRIPTION_ORDER =
  "setDashboardSubscriptionOrders";
export const PURGE_DASHBOARD_SUBSCRIPTION_ORDER =
  "purgeDashboardSubscriptionOrders";
export const SET_DASHBOARD_SUBSCRIPTION_OPTIONS_ORDER =
  "setDashboardSubscriptionOptions";
export const PURGE_DASHBOARD_SUBSCRIPTION_OPTIONS_ORDER =
  "purgeDashboardSubscriptionOptions";
export const SET_DASHBOARD_PROFILE_STATUS = "setDashboardProfileStatus";
export const PURGE_DASHBOARD_PROFILE_STATUS = "purgeDashboardProfileStatus";

const DASHBOARD_LAST_SUBSCRIPTION_ORDER_URL =
  "company/dashboard/last-subscription-order";
const DASHBOARD_SUBSCRIPTION_ORDERS_URL =
  "company/dashboard/subscription-orders";
const DASHBOARD_SUBSCRIPTION_ORDER_URL = "company/dashboard/subscription-order";
const DASHBOARD_SUBSCRIPTION_OPTIONS_ORDER_URL =
  "company/dashboard/subscription-options";
const DASHBOARD_PROFILE_STATUS_URL = "company/dashboard/profile-status";

const Dashboard = {
  state: {
    lastSubscriptionOrder: {},
    subscriptionOrdersList: {},
    subscriptionOrder: {},
    subscriptionOrderOptions: {},
    profileStatus: {}
  },
  getters: {
    getDashboardLastSubscriptionOrder(state) {
      return state.lastSubscriptionOrder;
    },
    getDashboardSubscriptionOrdersList(state) {
      return state.subscriptionOrdersList;
    },
    getDashboardSubscriptionOrder(state) {
      return state.subscriptionOrder;
    },
    getDashboardSubscriptionOptionsOrder(state) {
      return state.subscriptionOrderOptions;
    },
    getDashboardProfileStatus(state) {
      return state.profileStatus;
    }
  },
  actions: {
    [LOAD_LAST_DASHBOARD_SUBSCRIPTION_ORDER](context) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(DASHBOARD_LAST_SUBSCRIPTION_ORDER_URL)
          .then(response => {
            context.commit(SET_LAST_DASHBOARD_SUBSCRIPTION_ORDER, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_LAST_DASHBOARD_SUBSCRIPTION_ORDER);
            reject(error);
          });
      });
    },
    [LOAD_DASHBOARD_SUBSCRIPTION_ORDERS_LIST](context) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(DASHBOARD_SUBSCRIPTION_ORDERS_URL)
          .then(response => {
            context.commit(SET_DASHBOARD_SUBSCRIPTION_ORDERS_LIST, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_DASHBOARD_SUBSCRIPTION_ORDERS_LIST);
            reject(error);
          });
      });
    },
    [LOAD_DASHBOARD_SUBSCRIPTION_ORDER](context, payload) {
      return new Promise((resolve, reject) => {
        let url = DASHBOARD_SUBSCRIPTION_ORDER_URL + "/" + payload.id;
        ApiService.setHeader();
        ApiService.get(url)
          .then(response => {
            context.commit(SET_DASHBOARD_SUBSCRIPTION_ORDER, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_DASHBOARD_SUBSCRIPTION_ORDER);
            reject(error);
          });
      });
    },
    [LOAD_DASHBOARD_SUBSCRIPTION_OPTIONS_ORDER](context, payload) {
      return new Promise((resolve, reject) => {
        let url =
          DASHBOARD_SUBSCRIPTION_OPTIONS_ORDER_URL +
          "/" +
          payload.orderId +
          "/" +
          payload.optionsId;
        ApiService.setHeader();
        ApiService.get(url)
          .then(response => {
            context.commit(SET_DASHBOARD_SUBSCRIPTION_OPTIONS_ORDER, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_DASHBOARD_SUBSCRIPTION_OPTIONS_ORDER);
            reject(error);
          });
      });
    },
    [LOAD_DASHBOARD_PROFILE_STATUS](context) {
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(DASHBOARD_PROFILE_STATUS_URL)
          .then(response => {
            context.commit(SET_DASHBOARD_PROFILE_STATUS, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_DASHBOARD_PROFILE_STATUS);
            reject(error);
          });
      });
    }
  },
  mutations: {
    [SET_LAST_DASHBOARD_SUBSCRIPTION_ORDER](state, response) {
      state.lastSubscriptionOrder = response.data;
    },
    [PURGE_LAST_DASHBOARD_SUBSCRIPTION_ORDER](state) {
      state.lastSubscriptionOrder = {};
    },
    [SET_DASHBOARD_SUBSCRIPTION_ORDERS_LIST](state, response) {
      state.subscriptionOrdersList = response.data;
    },
    [PURGE_DASHBOARD_SUBSCRIPTION_ORDERS_LIST](state) {
      state.subscriptionOrdersList = {};
    },
    [SET_DASHBOARD_SUBSCRIPTION_ORDER](state, response) {
      state.subscriptionOrder = response.data;
    },
    [PURGE_DASHBOARD_SUBSCRIPTION_ORDER](state) {
      state.subscriptionOrder = {};
    },
    [SET_DASHBOARD_SUBSCRIPTION_OPTIONS_ORDER](state, response) {
      state.subscriptionOrderOptions = response.data;
    },
    [PURGE_DASHBOARD_SUBSCRIPTION_OPTIONS_ORDER](state) {
      state.subscriptionOrderOptions = {};
    },
    [SET_DASHBOARD_PROFILE_STATUS](state, response) {
      state.profileStatus = response.data;
    },
    [PURGE_DASHBOARD_PROFILE_STATUS](state) {
      state.profileStatus = {};
    }
  }
};

export default Dashboard;
